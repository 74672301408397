import { isIpAddress } from './is-ip-address'

type Params = {
  req: {
    headers: {
      host?: string
    }
    query?: {
      slug?: string
    }
  }
}

export const getLocationSlug = (context: Params): string => {
  if (process.env.FORCE_OVERRIDE_SLUG) {
    return process.env.FORCE_OVERRIDE_SLUG
  }
  const { host } = context.req.headers

  const { slug: slugFromQuery } = context.req.query ?? {}

  if (slugFromQuery && typeof slugFromQuery === 'string') {
    return slugFromQuery
  }
  if (host && isIpAddress(host)) {
    return ''
  }
  return getSlugFromHost(host)
}

export const getSlugFromHost = (host?: string): string => {
  const subdomain = host?.split('.')[0]
  if (!subdomain) {
    throw new Error(`Couldn't get subdomain from host ${host}`)
  }
  return subdomain
}
