import React from 'react'

const OPTIMIZED_PATH_DATA_BY_DIRECTION = {
  down: 'm8 10 4 4 4-4',
  up: 'm16 14-4-4-4 4',
  left: 'm14 8-4 4 4 4',
  right: 'm10 16 4-4-4-4',
} as const

interface Props extends React.SVGProps<SVGSVGElement> {
  direction?: keyof typeof OPTIMIZED_PATH_DATA_BY_DIRECTION
  color?: string
  size?: number
}

export const Chevron: React.FC<Props> = ({
  direction = 'down',
  color = '#000',
  size = 24,
  ...rest
}) => {
  const data = OPTIMIZED_PATH_DATA_BY_DIRECTION[direction]
  return (
    <svg
      fill="none"
      height={size}
      viewBox="0 0 24 24"
      width={size}
      xmlns="http://www.w3.org/2000/svg"
      {...rest}>
      <path d={data} stroke={color} strokeWidth="1.5" />
    </svg>
  )
}
