import * as t from 'io-ts'

export const Config = t.type({
  STOREFRONT_SERVICE_HOST: t.string,
  STOREFRONT_SERVICE_PORT: t.number,
  STOREFRONT_SERVICE_TOKEN_SECRET: t.string,
  STOREFRONT_SERVICE_TOKEN_AUDIENCE: t.string,
  STOREFRONT_SERVICE_TOKEN_ISSUER: t.string,
  ABLY_SUBSCRIPTION_KEY: t.string,
  STOREFRONT_PAY_URL: t.string,
  ADYEN_API_KEY: t.string,
})

export type Config = t.TypeOf<typeof Config>
