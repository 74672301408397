type Props = {
  className: string
  color?: string
}

export const HorizontalKarmaLogo = ({ className, color = '000' }: Props) => (
  <svg className={className} viewBox="0 0 680 232" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M236.018 122.719H210.046L223.082 96.4457L236.018 122.719Z" fill={color} />
    <path
      d="M338.467 94.1393H310.289V112.791H338.467C342.378 112.791 345.487 111.989 347.693 110.284C349.799 108.68 350.902 106.373 350.902 103.465C350.902 100.557 349.799 98.2507 347.693 96.546C345.487 94.9415 342.378 94.1393 338.467 94.1393Z"
      fill={color}
    />
    <path d="M547.546 122.719H573.518L560.582 96.4457L547.546 122.719Z" fill={color} />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M680 0H0V232H680V0ZM80 80V152H96.7465V132.145L110.084 120.713L138.262 152H159.922L122.217 110.284L157.515 80H134.451L96.7465 112.891V80H80ZM177.756 152H195.605L203.227 136.557H242.736L250.358 152H268.508L232.709 80H213.756L177.756 152ZM349.899 152L333.554 126.429H310.289V152H293.542V80H339.37C348.094 80 354.913 82.0056 359.927 86.117C364.84 90.2284 367.347 95.844 367.347 103.164C367.347 108.479 365.943 112.891 363.136 116.602C360.328 120.312 356.317 123.019 351.102 124.724L369.052 152H349.899ZM474.087 96.546V152H490.332V80H465.463L443.101 131.543L420.638 80H395.368V152H411.714V96.546L436.081 152H449.619L474.087 96.546ZM533.105 152H515.256L551.256 80H570.208L606.008 152H587.858L580.236 136.557H540.727L533.105 152Z"
      fill={color}
    />
  </svg>
)

export default HorizontalKarmaLogo
