import { isLeft } from 'fp-ts/lib/Either'
import { Decoder } from 'io-ts'
import reporter from 'io-ts-reporters'

import { logger } from './logger'

type Options = {
  silent?: boolean
}

export class ResponseValidationError extends Error {}

export const validate = <U>(
  validation: Decoder<unknown, U>,
  res: unknown,
  options?: Options
): U => {
  const { silent = false } = options ?? {}
  const decoded = validation.decode(res)
  const report = reporter.report(decoded)
  if (isLeft(decoded)) {
    if (!silent) {
      logger.error(`Validation failed for ${validation.name}`, { report })
    }
    const errorMessage = `Invalid format on response: ${JSON.stringify(report)}`
    throw new ResponseValidationError(errorMessage)
  }
  return decoded.right
}
