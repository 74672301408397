import isNumber from 'lodash/isNumber'
import reduce from 'lodash/reduce'
import split from 'lodash/split'

export const isIpAddress = (host: string) => {
  const blocks = split(host, '.')
  const result = reduce(
    blocks,
    (state, block) => isNumber(Number(block)) && Number(block) >= 0 && Number(block) <= 255,
    false
  )
  return result
}
