import * as SentryFrontend from '@sentry/browser'
import { Scope } from '@sentry/core'
import * as SentryBackend from '@sentry/nextjs'

type UserData = {
  clientId: string
  storefrontSessionId: string
  // TODO(christoffer) Add logged in user identifier here (user.third_party_id). Not adding right now
  // because it's a bit involved to change the API to setUserForProfiling to handle null session
  // (but logged in user) vs null session (not logged in user).
}

const scopeConfigurator = (userData: UserData | null) => {
  return (scope: Scope) => {
    if (userData === null) {
      scope.setUser(null)
      return
    }
    const existingUserData = scope.getUser() ?? {}
    scope.setUser({
      ...existingUserData,
      id: userData.clientId,
      storefrontSessionId: userData.storefrontSessionId,
    })
  }
}

const _isServerSide = () => typeof window === 'undefined'

export const setUserForProfiling = (userData: UserData | null) => {
  if (_isServerSide()) {
    SentryBackend.configureScope(scopeConfigurator(userData))
  } else {
    SentryFrontend.configureScope(scopeConfigurator(userData))
  }
}

const _getSentryTraceId = (sentry: typeof SentryFrontend | typeof SentryBackend) => {
  let traceId: string | undefined
  sentry.withScope((scope) => {
    traceId = scope.getSpan()?.toTraceparent()
  })
  return traceId
}

export const getSentryTraceId = () => {
  return _getSentryTraceId(_isServerSide() ? SentryBackend : SentryFrontend)
}

export const getHeadersWithSentryTraceId = (headers: { [key: string]: string }) => {
  const traceId = getSentryTraceId()
  if (traceId) {
    return {
      ...headers,
      'sentry-trace': traceId,
    }
  }
  return headers
}
