import Link from 'next/link'
import React, { forwardRef } from 'react'

import { LoadingSpinner } from '../../components'
import ButtonStyles from './Button.module.css'

export type ButtonType =
  | 'primary'
  | 'secondary'
  | 'tertiary'
  | 'quaternary'
  | 'info'
  | 'danger'
  | 'text'
  | 'ai'

export type ButtonSize = 'small' | 'medium'

type ButtonProps = {
  type?: 'button' | 'submit' | 'reset'
}

type LinkProps = {
  href?: string
}

type SharedBaseProps = {
  block?: boolean
  className?: string
  loading?: boolean
  buttonSize?: ButtonSize
  type?: ButtonType
  disabled?: boolean
  href?: string
}

type Props = SharedBaseProps &
  (ButtonProps | LinkProps) & {
    children: React.ReactNode
    onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void
  }

export const Button = forwardRef<HTMLButtonElement | HTMLAnchorElement, Props>(
  (
    {
      block = false,
      children,
      className,
      disabled,
      loading = false,
      buttonSize = 'medium',
      type = 'primary',
      href,
      onClick,
      ...props
    },
    ref
  ) => {
    const classes = [ButtonStyles['karmaui-btn']]

    if (block) {
      classes.push(ButtonStyles['karmaui-btn-w-full'])
    }

    if (type) {
      classes.push(ButtonStyles[`karmaui-btn--${type}`])
    }

    if (buttonSize) {
      classes.push(ButtonStyles[`karmaui-btn--${buttonSize}`])
    }

    if (className) {
      classes.push(className)
    }

    const commonProps = {
      className: classes.join(' '),
      ...props,
    }

    return (
      <>
        {href ? (
          <Link href={href} passHref ref={ref as React.Ref<HTMLAnchorElement>} {...commonProps}>
            {children}
          </Link>
        ) : (
          <button
            type="button"
            onClick={onClick}
            disabled={disabled || loading}
            ref={ref as React.Ref<HTMLButtonElement>}
            {...commonProps}>
            {loading && (
              <LoadingSpinner
                className={`w-full max-h-6`}
                color={`${type === 'primary' ? '#FFF' : '#000'}`}
              />
            )}
            {!loading && children}
          </button>
        )}
      </>
    )
  }
)

Button.displayName = 'KarmaButton'
