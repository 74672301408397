import * as t from 'io-ts'
import { UUID } from 'io-ts-types/lib/UUID'

export const ItemRecommendation = t.type({
  saleItemUuid: UUID,
  title: t.string,
  description: t.string,
  priceInCents: t.number,
  imageUrl: t.string,
})
export type ItemRecommendation = t.TypeOf<typeof ItemRecommendation>

export const ItemRecommendations = t.array(ItemRecommendation)
