import { ReactNode } from 'react'

import TextStyles from './Text.module.css'

export type TextColor =
  | 'default'
  | 'custom'
  | 'secondary'
  | 'tertiary'
  | 'inverted'
  | 'success'
  | 'info'
  | 'inherit'
  | 'warning'
  | 'error'
  | 'ai'
  | '[theme]'

type Props = {
  children: ReactNode
  className?: string
  color?: TextColor
  level?: 1 | 2 | 3 | 4
  weight?: 'regular' | 'medium' | 'bold' | 'light'
  style?: React.CSSProperties
  shouldUseBrandFont?: boolean
}

export function Text({
  children,
  className,
  color = 'default',
  level = 1,
  weight = 'regular',
  style,
  shouldUseBrandFont = false,
}: Props) {
  const classes = [TextStyles['karmaui-typography-text']]

  if (color) {
    classes.push(TextStyles[`karmaui-typography-text--${color}`])
  }

  if (level) {
    classes.push(TextStyles[`karmaui-typography-text--${level}`])
  }

  if (weight) {
    classes.push(TextStyles[`karmaui-typography-text--${weight}`])
  }

  if (shouldUseBrandFont) {
    classes.push(TextStyles['karmaui-typography-text--brand-font'])
  }

  if (className) {
    classes.push(className)
  }

  return (
    <span className={classes.join(' ')} style={style}>
      {children}
    </span>
  )
}
