import * as t from 'io-ts'

export const OrderState = t.union([
  t.literal('pending'),
  t.literal('accepted'),
  t.literal('ready_for_pickup'),
  t.literal('picked_up'),
  t.literal('rejected'),
])

export type OrderState = t.TypeOf<typeof OrderState>
