/* eslint-disable no-console */
type LogLevel = 'debug' | 'info' | 'warn' | 'error'
type LogFunction = typeof console.log

export type Logger = { [level in LogLevel]: LogFunction } & {
  getCid: () => string
}

// eslint-disable-next-line no-shadow
enum LogSeverity {
  DEFAULT = 'DEFAULT', // 	(0) The log entry has no assigned severity level.
  DEBUG = 'DEBUG', // 	(100) Debug or trace information.
  INFO = 'INFO', // 	(200) Routine information, such as ongoing status or performance.
  NOTICE = 'NOTICE', // 	(300) Normal but significant events, such as start up, shut down, or a configuration change.
  WARNING = 'WARNING', // 	(400) Warning events might cause problems.
  ERROR = 'ERROR', // 	(500) Error events are likely to cause problems.
  CRITICAL = 'CRITICAL', // 	(600) Critical events cause more severe problems or outages.
  ALERT = 'ALERT', // 	(700) A person must take an action immediately.
  EMERGENCY = 'EMERGENCY', //
}

type LogEntry = {
  severity: LogSeverity
  message: string
  component: Record<string, unknown>
} & Record<string, unknown>

const globalLogFields = {}

type ICorrelationLogger = {
  cid: string
}
export const CorrelationLogger = ({ cid }: ICorrelationLogger): Logger => ({
  debug: (message: string, ...component: unknown[]): void => {
    const entry: LogEntry = {
      severity: LogSeverity.DEBUG,
      message,
      component: { data: component, cid },
      ...globalLogFields,
    }
    console.log(JSON.stringify(entry))
  },
  warn: (message: string, ...component: unknown[]): void => {
    const entry: LogEntry = {
      severity: LogSeverity.WARNING,
      message,
      component: { data: component, cid },
      ...globalLogFields,
    }
    console.log(JSON.stringify(entry))
  },
  info: (message: string, ...component: unknown[]): void => {
    const entry: LogEntry = {
      severity: LogSeverity.INFO,
      message,
      component: { data: component, cid },
      ...globalLogFields,
    }
    console.log(JSON.stringify(entry))
  },
  error: (message: string, ...component: unknown[]): void => {
    const entry: LogEntry = {
      severity: LogSeverity.ERROR,
      message,
      component: { data: component, cid },
      ...globalLogFields,
    }
    console.log(JSON.stringify(entry))
  },
  getCid: (): string => cid,
})

export const logger = {
  debug: (message: string, ...component: unknown[]): void => {
    const entry: LogEntry = {
      severity: LogSeverity.DEBUG,
      message,
      component: { data: component },
      ...globalLogFields,
    }
    console.log(JSON.stringify(entry))
  },
  warn: (message: string, ...component: unknown[]): void => {
    const entry: LogEntry = {
      severity: LogSeverity.WARNING,
      message,
      component: { data: component },
      ...globalLogFields,
    }
    console.log(JSON.stringify(entry))
  },
  info: (message: string, ...component: unknown[]): void => {
    const entry: LogEntry = {
      severity: LogSeverity.INFO,
      message,
      component: { data: component },
      ...globalLogFields,
    }
    console.log(JSON.stringify(entry))
  },
  error: (message: string, ...component: unknown[]): void => {
    const entry: LogEntry = {
      severity: LogSeverity.ERROR,
      message,
      component: { data: component },
      ...globalLogFields,
    }
    console.log(JSON.stringify(entry))
  },
  getCid: (): string => 'bootstrap',
}
