import * as t from 'io-ts'
import { UUID } from 'io-ts-types/lib/UUID'

import { Choice as VariantChoice, CurrencyCode, nullable } from '../../models/types'

export const Comment = t.type({
  comment_id: UUID,
  kitchen_dispatch_item_id: nullable(UUID),
  comment_text: t.string,
  sender: t.string,
  commenter_tab_member_uuid: UUID,
  commenter_name: nullable(t.string),
  commenter_email: nullable(t.string),
  created_at: nullable(t.string),
})

export type Comment = t.TypeOf<typeof Comment>

export const LightKitchenDispatchItem = t.intersection([
  t.type({
    id: UUID,
  }),
  t.partial({
    variant_ids: t.array(t.number),
  }),
])
export type LightKitchenDispatchItem = t.TypeOf<typeof LightKitchenDispatchItem>

export const KDItemWithVariants = t.type({
  id: UUID,
  variant_selection: t.array(VariantChoice),
})
export type KDItemWithVariants = t.TypeOf<typeof KDItemWithVariants>

export const ReadKitchenDispatchItemsForTabResponse = t.type({
  item_id: t.number,
  sku: UUID,
  title: t.string,
  price: t.number,
  modifications_price: t.number,
  currency: CurrencyCode,
  country: t.string,
  total_modification_price_sum: t.number,
  kitchen_dispatch_items: t.array(KDItemWithVariants),
  quantity: t.number,
  value: t.number,
  comment: nullable(Comment),
})

type ReadKitchenDispatchItemsForTabResponse = t.TypeOf<
  typeof ReadKitchenDispatchItemsForTabResponse
>

export const ReadCartResponse = t.type({
  data: t.type({
    tabId: UUID,
    kitchenDispatchId: nullable(UUID),
    items: t.array(ReadKitchenDispatchItemsForTabResponse),
    totalValue: t.number,
    totalCount: t.number,
  }),
})

export type ReadCartResponse = t.TypeOf<typeof ReadCartResponse>

export const SendOrderToKitchenResponse = t.null

export type SendOrderToKitchenResponse = t.TypeOf<typeof SendOrderToKitchenResponse>
