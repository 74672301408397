import { commonTypes as SaleEntities } from '@karmalicious/sale-service-sdk'
import { Time } from '@karmalicious/type-helpers'
import { IsoTimestamp } from '@karmalicious/type-helpers/lib/time'
import * as t from 'io-ts'
import { UUID } from 'io-ts-types/lib/UUID'

import { ItemRecommendations } from '../components/item-recommendations/types'
import { OrderState } from './OrderState'

export const nullable = <T extends t.Mixed>(x: T) => t.union([x, t.null])

export const CurrencyCode = t.union([
  t.literal('SEK'),
  t.literal('GBP'),
  t.literal('EUR'),
  t.literal('DKK'),
  t.literal('NOK'),
  t.literal('ISK'),
])
export type CurrencyCode = t.TypeOf<typeof CurrencyCode>

const Bucket = t.intersection([
  t.type({
    id: t.number,
    name: t.string,
    sortOrder: t.union([t.null, t.number]),
  }),
  t.partial({ isHidden: t.boolean }),
])

const Type = t.union([t.literal('single_choice'), t.literal('multi_choice')])

export const Choice = t.type({
  title: t.string,
  variant_id: t.number,
  default: nullable(t.boolean),
  price: t.number,
})
export type Choice = t.TypeOf<typeof Choice>

const ChoiceGroup = t.intersection([
  t.type({
    id: t.number,
    title: t.string,
    type: Type,
    choices: t.array(Choice),
  }),
  t.partial({
    min_required: t.number,
    max_allowed: t.number,
  }),
])
export type ChoiceGroup = t.TypeOf<typeof ChoiceGroup>
export type VariantId = Choice['variant_id'] // Nicer name for collection typing

export const Item = t.intersection([
  t.type({
    item_id: t.number,
    name: t.string,
    sku: t.string,
    originalPrice: t.number,
    discountPercentage: t.number,
    price: t.number,
    availableQuantity: t.union([t.number, t.null]),
    currency: CurrencyCode,
    description: t.union([t.string, t.null]),
    image: t.union([t.string, t.null]),
    type: t.union([t.literal('SURPLUS'), t.literal('NON_SURPLUS')]),
    category: t.union([
      t.null,
      t.type({
        key: t.string,
        translations: t.type({
          en: t.string,
          sv: t.string,
          fr: t.string,
        }),
      }),
    ]),
    allergens: t.array(
      t.type({
        key: t.string,
        translations: t.type({
          en: t.string,
          sv: t.string,
          fr: t.string,
        }),
      })
    ),
    preferences: t.array(
      t.type({
        key: t.string,
        translations: t.type({
          en: t.string,
          sv: t.string,
          fr: t.string,
        }),
      })
    ),
    customTags: t.union([
      t.array(t.type({ key: t.string, type: t.string, lang: t.string })),
      t.null,
    ]),
    buckets: t.union([t.null, t.array(Bucket)]),
    itemOrder: t.union([t.null, t.number]),
  }),
  t.partial({
    variantGroups: nullable(t.array(ChoiceGroup)),
  }),
])

export type Item = t.TypeOf<typeof Item>
export const InventoryItem = t.intersection([
  Item,
  t.partial({ isBestSeller: t.boolean, itemRecommendations: nullable(ItemRecommendations) }),
])
export type InventoryItem = t.TypeOf<typeof InventoryItem>

export const GetInventoryResponse = t.array(InventoryItem)
export type GetInventoryResponse = t.TypeOf<typeof GetInventoryResponse>

const DailyOpeningHours = t.type({
  open: t.string,
  close: t.string,
  pickup_start: t.union([t.string, t.null]),
  pickup_end: t.string,
  closed: t.boolean,
})

export const OpeningHours = t.union([
  t.type({
    monday: DailyOpeningHours,
    tuesday: DailyOpeningHours,
    wednesday: DailyOpeningHours,
    thursday: DailyOpeningHours,
    friday: DailyOpeningHours,
    saturday: DailyOpeningHours,
    sunday: DailyOpeningHours,
  }),
  t.null,
])

export type OpeningHours = t.TypeOf<typeof OpeningHours>

export const LocationTheme = t.intersection([
  t.type({
    id: t.number,
  }),
  t.partial({
    name: nullable(t.string),
    logo: nullable(t.string),
    brandPrimaryColor: nullable(t.string),
    brandColorTextOnPrimary: nullable(t.string),
    brandFontPath: nullable(t.string),
    brandFontFamily: nullable(t.string),
  }),
])
export type LocationTheme = t.TypeOf<typeof LocationTheme>

export const LocationCenter = t.type({
  id: t.number,
  name: t.string,
  logo: nullable(t.string),
  visibleMenuGroups: t.array(t.number),
  themeId: nullable(t.number),
})

export type LocationCenter = t.TypeOf<typeof LocationCenter>

export const ExtendedWaitingTime = t.type({
  id: t.number,
  locationId: t.number,
  endsAt: Time.IsoTimestamp,
  delayInMinutes: t.union([t.number, t.null]),
})

export type ExtendedWaitingTime = t.TypeOf<typeof ExtendedWaitingTime>

export const LocationInformation = t.intersection([
  t.type({
    locationId: t.number,
    name: t.string,
    description: t.union([t.string, t.null]),
    latitude: t.number,
    longitude: t.number,
    streetAdress: t.union([t.string, t.null]),
    zip: t.union([t.string, t.null]),
    city: t.union([t.string, t.null]),
    website: t.union([t.string, t.null]),
    currencyCode: CurrencyCode,
    logoImageUrl: t.union([t.string, t.null]),
    featureImageUrl: t.union([t.string, t.null]),
    pickupInformation: t.union([t.string, t.null]),
    openingHours: OpeningHours,
    averageRating: t.union([t.null, t.number]),
    saleStatus: SaleEntities.SaleStatus,
    saleStart: nullable(IsoTimestamp),
    saleEnd: nullable(IsoTimestamp),
    salePickupStart: nullable(IsoTimestamp),
    salePickupEnd: nullable(IsoTimestamp),
    defaultPreparationTimeSeconds: t.number,
    extendedWaitingTime: nullable(ExtendedWaitingTime),
    isItemCommentAllowed: t.boolean,
    takeawayEnabled: t.boolean,
    isDiscountsEnabled: t.boolean,
    isScanToTabEnabled: t.boolean,
    isSplitItemEnabled: t.boolean,
  }),
  t.partial({
    smsNotifications: t.boolean,
    swishConfig: t.union([
      t.type({
        number: t.number,
      }),
      t.null,
    ]),
    themes: nullable(t.array(LocationTheme)),
    locationCenters: nullable(t.array(LocationCenter)),
    tip: t.boolean,
    externalName: nullable(t.string),
    externalTos: nullable(t.array(t.type({ name: t.string, link: t.string }))),
    isUserSessionsEnabled: t.boolean,
  }),
])

export type LocationInformation = t.TypeOf<typeof LocationInformation>

export const GetLocationInfoResponse = LocationInformation
export type GetLocationInfoResponse = t.TypeOf<typeof GetLocationInfoResponse>

export const GetSessionLookupResponse = t.type({
  linkUUID: t.string,
  linkToken: t.string,
})
export type GetSessionLookupResponse = t.TypeOf<typeof GetSessionLookupResponse>

const PaymentItem = t.type({
  count: t.number,
  price: t.number,
  title: t.string,
  weight: t.number,
  item_id: t.number,
  sale_id: t.number,
  item_type: t.string,
  vat_value: t.number,
  base_price: t.number,
  vat_amount: t.number,
  total_price: t.number,
  item_based_fee: t.number,
  discount_percentage: t.number,
})

export const PaymentExtra = t.type({
  id: t.string,
  name: t.string,
  type: t.string,
  amount: t.number,
  vat_value: t.number,
  vat_amount: t.number,
})

export const KitchenDispatchInfo = t.type({
  orderState: OrderState,
  desiredPickupTime: nullable(t.number),
  dailyOrdinalNumber: t.number,
})
export type KitchenDispatchInfo = t.TypeOf<typeof KitchenDispatchInfo>

export const DeliveryKind = t.union([
  t.literal('TABLE_ORDER'),
  t.literal('TAKEOUT'),
  t.literal('COUNTER_PICKUP'),
])
export type DeliveryKind = t.TypeOf<typeof DeliveryKind>

export const VatBreakdown = t.record(t.string, t.number)
export type VatBreakdown = t.TypeOf<typeof VatBreakdown>

export const DiscountBreakdown = t.record(t.string, t.number)
export type DiscountBreakdown = t.TypeOf<typeof DiscountBreakdown>

export const PaymentFlow = t.union([t.literal('pay_upfront'), t.literal('open_tab')])
export type PaymentFlow = t.TypeOf<typeof PaymentFlow>

export const Order = t.type({
  uuid: t.string,
  userId: t.string,
  email: t.string,
  name: nullable(t.string),
  createdAt: t.number,
  updatedAt: t.number,
  storeName: t.string,
  amount: t.number,
  billedAmount: t.number,
  currency: CurrencyCode,
  currencyString: t.string,
  paymentId: t.number,
  feeExVat: t.number,
  feeRate: t.number,
  feeVatAmount: t.number,
  feeVatRate: t.number,
  pickupDate: t.number,
  pickupStartDate: t.number,
  pickedUp: t.boolean,
  pickedUpDate: t.union([t.number, t.null]),
  sourceBrand: t.string,
  sourceLast4: t.string,
  vatAmount: t.number,
  vatBreakdown: VatBreakdown,
  items: t.array(PaymentItem),
  extras: t.union([t.null, t.array(PaymentExtra)]),
  tableName: nullable(t.string),
  // NOTE(christoffer) isTableOrder has been replaced with deliveryKind in storefront-service.
  // Maintaining both here to allow independent deploy of storefront-web.
  // TODO(christoffer) Remove isTableOrder field and remove nullability of deliveryKind once
  // https://github.com/karmadev/storefront-service/pull/122 lands and is rolled out to production.
  isTableOrder: nullable(t.boolean),
  deliveryKind: nullable(DeliveryKind),
  //
  kitchenDispatchInfos: t.array(KitchenDispatchInfo),
  paymentFlow: t.union([t.null, PaymentFlow]),
})

export type Order = t.TypeOf<typeof Order>
export type Bucket = t.TypeOf<typeof Bucket>

export interface BucketWithSKUs extends Bucket {
  items: Item[]
}

