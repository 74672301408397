import { validate } from '../validation'
import { Config } from './types'

export const setupConfig = (): Config => {
  const config = {
    STOREFRONT_SERVICE_HOST: process.env.STOREFRONT_SERVICE_HOST,
    STOREFRONT_SERVICE_PORT: Number(process.env.STOREFRONT_SERVICE_PORT),
    STOREFRONT_SERVICE_TOKEN_SECRET: process.env.STOREFRONT_SERVICE_TOKEN_SECRET,
    STOREFRONT_SERVICE_TOKEN_AUDIENCE: process.env.STOREFRONT_SERVICE_TOKEN_AUDIENCE,
    STOREFRONT_SERVICE_TOKEN_ISSUER: process.env.STOREFRONT_SERVICE_TOKEN_ISSUER,
    ABLY_SUBSCRIPTION_KEY: process.env.ABLY_SUBSCRIPTION_KEY,
    STOREFRONT_PAY_URL: process.env.STOREFRONT_PAY_URL,
    ADYEN_API_KEY: process.env.ADYEN_API_KEY,
  }

  return validate(Config, config)
}

export * from './types'
