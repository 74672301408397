import { ReactNode } from 'react'

import HeadingStyles from './Heading.module.css'

interface Props {
  children: ReactNode
  className?: string
  level?: 1 | 2 | 3 | 4 | 5 | 6
  weight?: 'regular' | 'medium' | 'bold'
  color?: 'default' | 'ai' | 'brand'
  shouldUseBrandFont?: boolean
}

export function Heading({
  children,
  className,
  level = 1,
  weight = 'bold',
  color = 'default',
  shouldUseBrandFont = true,
}: Props) {
  const classes = [HeadingStyles['karmaui-typography-heading']]

  if (className) {
    classes.push(className)
  }

  if (weight) {
    classes.push(HeadingStyles[`karmaui-typography-heading--${weight}`])
  }

  if (color) {
    classes.push(HeadingStyles[`karmaui-typography-heading--${color}`])
  }

  if (shouldUseBrandFont) {
    classes.push(HeadingStyles['karmaui-typography-heading--brand-font'])
  }

  const HeadingTag: keyof JSX.IntrinsicElements = `h${level}`

  return <HeadingTag className={classes.join(' ')}>{children}</HeadingTag>
}
